import { MessageCampaignEditor } from '../components/MessageCampaign/Editor';

function MessageCampaignEditorRoute() {
  return <MessageCampaignEditor />;
}

export const Component = MessageCampaignEditorRoute;

export function clientLoader() {
  return null;
}
